import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private history: string[] = [];

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.history.length === 0) {
          this.history.push(event.urlAfterRedirects);
          return;
        }
        if (this.history.indexOf(event.urlAfterRedirects) === -1) this.history.push(event.urlAfterRedirects);

        if (event.urlAfterRedirects === '/home') this.history = [];
      });
  }

  back(): void {
    // Implementation depending on how you want to use the history
    // For example, to navigate back to the previous URL in history:
    if (this.history.length > 1) {
      // Ensure there is a previous URL to navigate back to
      this.history.pop(); // Remove the current URL
      const previousUrl = this.history.pop(); // Get the previous URL
      this.router.navigateByUrl(previousUrl || '/');
    }
  }
}
