import { Component, DestroyRef, Input, booleanAttribute, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonTitle,
  ModalController,
} from '@ionic/angular/standalone';
import { HistoryService } from 'src/app/services/helpers/history.service';
@Component({
  selector: 'app-header',
  template: `
    <ion-header>
      <ion-toolbar color="secondary" class="shadow">
        @if (title && title.length > 0) {
          <ion-title>{{ title }}</ion-title>
        }
        <ion-buttons slot="start">
          @if (showBackButton) {
            <ion-button (click)="onBack()" color="primary">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12H19" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 12L9 16" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 12L9 8" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </ion-button>
          }
        </ion-buttons>
        @if (extraButtons.length > 0) {
          <ion-buttons slot="end">
            @for (button of extraButtons; track $index) {
              <ion-button (click)="button.onClick()">
                <img [src]="button.icon" />
              </ion-button>
            }
          </ion-buttons>
        }
      </ion-toolbar>
    </ion-header>
  `,
  standalone: true,
  imports: [RouterModule, CommonModule, IonHeader, IonToolbar, IonButtons, IonButton, IonBackButton, IonIcon, IonTitle],
})
export class HeaderComponent {
  @Input() title: string = '';
  @Input({ transform: booleanAttribute }) showBackButton: boolean = false;
  @Input({ transform: booleanAttribute }) isModal: boolean = false;
  @Input()
  backUrl: string = '';
  @Input() extraButtons: {
    icon: string;
    onClick: () => void;
  }[] = [];

  destroyRef = inject(DestroyRef);
  location = inject(Location);
  history = inject(HistoryService);
  router = inject(Router);
  modalCtrl = inject(ModalController);

  async onBack() {
    if (this.isModal) {
      await this.modalCtrl.dismiss();
      return;
    }
    if (this.backUrl && this.backUrl.length > 0) {
      this.router.navigateByUrl(this.backUrl);
    } else {
      this.history.back();
    }
  }
}
